import React from "react";
import styled from "styled-components";
import COLORS from "../constants/colors";

const FooterEl = styled.footer`
  background: ${COLORS.footerGrey};
  color: ${COLORS.darkGrey};
  font-weight: bold;
  text-align: center;
  padding: 0.5rem 1.2rem;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  a {
    color: inherit;
  }
`;

const Footer = () => {
  return (
    <FooterEl>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
      {` `}💜
    </FooterEl>
  );
};

export default Footer;
