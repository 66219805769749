import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import COLORS from '../constants/colors';

import Footer from './footer';
import './layout.css';

const LayoutEl = styled.div`
  padding-bottom: 72px;
  min-height: 100vh;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    margin: 0 64px;

    @media screen and (max-width: 640px) {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: ${COLORS.baseColor};

      &:hover {
        color: ${COLORS.baseColorDarker};
      }

      h1 {
        font-size: 18px;
        margin: 0;
      }

      &.nav-links {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }

        @media screen and (max-width: 500px) {
          font-size: 12px;
          margin-right: 16px;
        }
      }
    }
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div style={{position: "relative"}}>
      <LayoutEl>
        <header>
          <section className="header-left">
            <Link to="/">
              <h1>BABS CRAIG</h1>
            </Link>
          </section>
          <section className="header-right">
            <nav>
              <Link className="nav-links" to="/">
                posts
              </Link>
              <Link className="nav-links" to="/setup">
                setup
              </Link>
              <Link className="nav-links" to="/links">
                connect
              </Link>
              <Link className="nav-links" to="/resume">
                resume
              </Link>
            </nav>
          </section>
        </header>
        <main>{children}</main>
      </LayoutEl>
      <Footer />
      </div>

    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
