export default {
  baseColor: "#47b4a6",
  baseColorDarker: "#27867a",
  lightGrey: "#8a8686",
  footerGrey: "#c5c5c5",
  darkGrey: "#929292",
  darkerGrey: "#5e5b5b",
  genericGrey: "#dddddd",
  anaemicRed: "#c83333"
};
